import Header from "../../Components/Header";
import "./style.css";
import i1 from "./1.png";
import i2 from "./2.png";
import i3 from "./3.png";
import i4 from "./4.png";
import rew from "./rew.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { Fade, Zoom } from "react-reveal";
import HeaderDesc from "../../Components/HeaderDesc";
import b1 from "./b1.png";
import cards from "./cards.png";
import vd from "./vd.png";
import { useEffect, useState } from "react";
import { autoAuth } from "../../Api";
const text = [
  "Зарегистрироваться на нашем портале",
  "Заполнить анкету",
  "Посмотреть бонусный урок 1/2/3/4",
  "Подписаться на тг каналы",
  "Зарегистрироваться на вебинар",
  "Зарегистрироваться в Партнерской Программе",
  "Пригласить друга",
];
export default function Main() {
  const Navigate = useNavigate();
  const [reviews, setReviews] = useState([]);
  const [change, setChange] = useState([]);
  const [currentZad, setCurrentZad] = useState(
    "Зарегистрироваться на нашем портале."
  );
  useEffect(() => {
    if (localStorage.user) {
      autoAuth().then((data) => {
        // localStorage.user = JSON.stringify(data);

        let arr = JSON.parse(localStorage.user).Bonus;
        setChange(JSON.parse(arr));
      });
    }
  }, []);
  useEffect(() => {
    if (change && change.length > 0) {
      setCurrentZad(text[change.indexOf(0)]);
    }
  }, [change]);
  useEffect(() => {
    fetch("https://app.opora.digital/taro/getReview.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setReviews(data);
      });
  }, []);
  return document.body.clientWidth < 640 ? (
    <div className="Main">
      <Header></Header>
      <span>Главное</span>
      <div className="classic">меню</div>
      <div className="menu">
        <Fade left>
          <div className="menuEm" onClick={() => Navigate(routes.cardChBefore)}>
            <img src={i1} alt="" />
            <p>
              Выбрать карту
              <span>из колоды</span>
            </p>
          </div>
        </Fade>
        <Fade right>
          <div className="menuEm" onClick={() => Navigate(routes.courses)}>
            <img src={i2} alt="" />
            <p>
              Обучающие
              <span>курсы</span>
            </p>
          </div>{" "}
        </Fade>
        <Zoom>
          <div className="rew" onClick={() => Navigate(routes.rewpage)}>
            <span>отзывы</span>
            <img src={rew} alt="" />
            <span>учеников</span>
          </div>
        </Zoom>
        <Fade left>
          <div className="menuEm" onClick={() => Navigate(routes.dop)}>
            <img src={i3} alt="" />
            <p>
              Бонусные
              <span> материалы</span>
            </p>
          </div>
        </Fade>
        <Fade right>
          <div
            className="menuEm"
            onClick={() =>
              localStorage.user
                ? Navigate(routes.profile)
                : Navigate(routes.registration)
            }
          >
            <img src={i4} alt="" />
            <p>
              Личный
              <span>кабинет</span>
            </p>
          </div>
        </Fade>
      </div>
    </div>
  ) : (
    <div className="mainDesc">
      <HeaderDesc></HeaderDesc>
      <div className="mainDescBody">
        <Fade left>
          <div className="mainDescLeft">
            <div className="hand">
              <p>
                Обучающие <span>курсы</span>
              </p>{" "}
              <div className="tas" onClick={() => Navigate(routes.courses)}>
                Перейти к курсам
              </div>
            </div>
          </div>
        </Fade>
        <div className="rightMainDesc">
          <Fade top>
            <div>
              <div className="descMMM">
                <img src={i3} alt="" />
                <p>
                  Бонусные
                  <span>материалы</span>
                </p>
                <div className="tas" onClick={() => Navigate(routes.dop)}>
                  Смотреть материалы
                </div>
              </div>
              <div
                className="descMMM vtor"
                onClick={() =>
                  localStorage.user
                    ? Navigate(routes.profileProgress)
                    : Navigate(routes.registration)
                }
              >
                <img src={i4} alt="" />
                <p>
                  Личный
                  <span>кабинет</span>
                </p>
                <div className="tas">Перейти в кабинет</div>
              </div>
              <div className="zad">
                <img src={b1} alt="" />
                <h3>Задание дня</h3>
                <p>{currentZad}</p>
                <div className="go">Выполнить</div>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="blya">
              <div className="cardsBlock">
                <img src={cards} alt="" />
                <div>
                  <p>
                    Выбрать карту
                    <span>из колоды</span>
                  </p>
                  <div
                    className="tas"
                    onClick={() => Navigate(routes.cardChoose)}
                  >
                    Выбрать
                  </div>
                </div>
              </div>
              <div className="rews">
                <h3>
                  Отзывы учеников{" "}
                  <span onClick={() => Navigate(routes.rewpage)}>
                    Смотреть отзывы{" "}
                    <svg
                      width="16"
                      height="20"
                      viewBox="0 0 16 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.5 8.63398C16.1667 9.01888 16.1667 9.98113 15.5 10.366L7.25 15.1292C6.58333 15.5141 5.75 15.0329 5.75 14.2631L5.75 4.73686C5.75 3.96706 6.58333 3.48593 7.25 3.87083L15.5 8.63398Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </h3>
                <div className="vidos">
                  {reviews &&
                    reviews.length > 0 &&
                    reviews.map((em, index) => {
                      if (index < 2) {
                        return (
                          <div
                            dangerouslySetInnerHTML={{ __html: em.Link }}
                          ></div>
                        );
                      }
                    })}
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

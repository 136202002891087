import { useEffect, useState } from "react";
import Header from "../../../Components/Header";
import "./style.css";
import HeaderDesc from "../../../Components/HeaderDesc";
import Profile from "../index";
import { getReferal } from "../../../Api";
export default function Referal() {
  const [user] = useState(
    localStorage.user ? JSON.parse(localStorage.user) : { ID: 1 }
  );
  const [countRef, setCountRef] = useState(0);
  useEffect(() => {
    getReferal(user.ID).then((data) => {
      setCountRef(data);
    });
  }, []);
  return document.body.clientWidth < 640 ? (
    <div className="refer">
      <Header></Header>
      <h3>ваши Рефералы</h3>
      <div className="refId">
        <p>Ваш ID-партнера {user.ID}</p>
        <input
          type="text"
          style={{ transform: "translateX(-12000px)", position: "absolute" }}
          name=""
          id="fff"
        />
        <label htmlFor="fff">
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.2966 6.52649C12.2714 9.5257 10.0796 12.0546 7.08481 12.5152C5.90349 12.697 4.7601 12.5447 3.66654 12.0638C3.29208 11.8989 2.92444 11.8719 2.53592 12.0073C2.01449 12.1891 1.48625 12.3519 0.963537 12.5308C0.680243 12.6274 0.423362 12.597 0.209082 12.3891C-0.0103111 12.176 -0.0460954 11.9183 0.0544419 11.631C0.23379 11.1189 0.397802 10.6009 0.57928 10.0897C0.717732 9.70075 0.694302 9.33165 0.525603 8.95496C-0.87212 5.82837 0.617621 2.17575 3.81181 0.884959C7.32039 -0.532808 11.2712 1.49113 12.1291 5.14796C12.176 5.34791 12.2135 5.55081 12.2369 5.75455C12.2663 6.01102 12.2774 6.26918 12.2961 6.52649H12.2966ZM6.14035 5.83933C5.75865 5.8368 5.45917 6.12534 5.45448 6.50034C5.44937 6.88294 5.74928 7.18244 6.13609 7.18159C6.51609 7.18075 6.81301 6.888 6.81387 6.51257C6.81472 6.1363 6.52035 5.84144 6.14035 5.83933ZM3.41264 7.18202C3.78966 7.17991 4.09084 6.87999 4.08956 6.50836C4.08828 6.13715 3.78582 5.83976 3.40838 5.83891C3.02498 5.83849 2.71442 6.14643 2.72166 6.52059C2.7289 6.89222 3.03435 7.18455 3.41264 7.18202ZM8.86892 7.18202C9.2455 7.17906 9.54882 6.87746 9.54712 6.50751C9.54541 6.1304 9.234 5.83301 8.84804 5.83891C8.47017 5.84482 8.17708 6.14432 8.18134 6.52059C8.1856 6.89222 8.48977 7.18455 8.86934 7.18202H8.86892Z"
              fill="white"
            />
          </svg>
          Копировать
        </label>
      </div>
      <div className="pp">
        <p>Кол-во партнеров</p>
        <div className="nums">{countRef} человек</div>
        <p>Ваше вознаграждение</p>
        <div className="nums">5 баллов</div>
      </div>
      <div className="tas">Обменять баллы</div>
    </div>
  ) : (
    <div className="refec">
      <HeaderDesc></HeaderDesc>
      <div className="refecBody">
        <Profile></Profile>
        <div className="referRightDesc">
          <h3>ваши Рефералы</h3>
          <div className="refId">
            <p>Ваш ID-партнера {user.ID}</p>
            <input
              type="text"
              style={{
                transform: "translateX(-12000px)",
                position: "absolute",
              }}
              name=""
              id="fff"
            />
            <label htmlFor="fff">
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.2966 6.52649C12.2714 9.5257 10.0796 12.0546 7.08481 12.5152C5.90349 12.697 4.7601 12.5447 3.66654 12.0638C3.29208 11.8989 2.92444 11.8719 2.53592 12.0073C2.01449 12.1891 1.48625 12.3519 0.963537 12.5308C0.680243 12.6274 0.423362 12.597 0.209082 12.3891C-0.0103111 12.176 -0.0460954 11.9183 0.0544419 11.631C0.23379 11.1189 0.397802 10.6009 0.57928 10.0897C0.717732 9.70075 0.694302 9.33165 0.525603 8.95496C-0.87212 5.82837 0.617621 2.17575 3.81181 0.884959C7.32039 -0.532808 11.2712 1.49113 12.1291 5.14796C12.176 5.34791 12.2135 5.55081 12.2369 5.75455C12.2663 6.01102 12.2774 6.26918 12.2961 6.52649H12.2966ZM6.14035 5.83933C5.75865 5.8368 5.45917 6.12534 5.45448 6.50034C5.44937 6.88294 5.74928 7.18244 6.13609 7.18159C6.51609 7.18075 6.81301 6.888 6.81387 6.51257C6.81472 6.1363 6.52035 5.84144 6.14035 5.83933ZM3.41264 7.18202C3.78966 7.17991 4.09084 6.87999 4.08956 6.50836C4.08828 6.13715 3.78582 5.83976 3.40838 5.83891C3.02498 5.83849 2.71442 6.14643 2.72166 6.52059C2.7289 6.89222 3.03435 7.18455 3.41264 7.18202ZM8.86892 7.18202C9.2455 7.17906 9.54882 6.87746 9.54712 6.50751C9.54541 6.1304 9.234 5.83301 8.84804 5.83891C8.47017 5.84482 8.17708 6.14432 8.18134 6.52059C8.1856 6.89222 8.48977 7.18455 8.86934 7.18202H8.86892Z"
                  fill="white"
                />
              </svg>
              Копировать
            </label>
          </div>
          <div className="pp">
            <p>Кол-во партнеров</p>
            <div className="nums">{countRef}</div>
            <p>Ваше вознаграждение</p>
            <div className="nums">{(countRef * 100) / 2} баллов</div>
          </div>
          <div className="tas">Обменять баллы</div>
        </div>
      </div>
    </div>
  );
}

import YouTube from "react-youtube";
import Header from "../../Components/Header";
import "./style.css";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import { Zoom } from "react-reveal";
import { useEffect, useState } from "react";
import { getCourses } from "../../Api";
export default function MaterilaEm() {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = JSON.parse(urlParams.get("key"));
  const [curses, setCurses] = useState([]);
  useEffect(() => {
    getCourses().then((data) => {
      setCurses(data);
    });
  }, []);
  const Navigate = useNavigate();

  return (
    <div className="matsEm">
      <Header></Header>

      <h3>{curses.length > 0 && curses[myParam].Title}</h3>
      <Zoom>
        <div className="linkVideo">
          <div
            dangerouslySetInnerHTML={{
              __html: curses.length > 0 && curses[myParam].Img,
            }}
          ></div>
        </div>
      </Zoom>
      <div className="matDesc">
        {curses.length > 0 && curses[myParam].DescCard}
      </div>
      <div
        id="scr"
        className="tas"
        onClick={() => {
          Navigate(routes.registration);
        }}
      >
        Получить
      </div>
    </div>
  );
}
